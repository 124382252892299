.manage-channels-row {
  width: 100%;
  .channels-card {
    width: 100%;
    height: 180px;
    background-color: #ffffff;
    border: 1px solid #eaeaea;
    padding: 25px;
    display: flex;
    margin-bottom: 15px;
    overflow-x: auto;
    .edit-icon {
      min-width: 100px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: 1px solid #eaeaea;
      color: #007c16;
    }
    .section {
      flex: 1;
      min-width: 250px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-right: 1px solid #eaeaea;
      .section-item {
        margin-bottom: 15px;
        color: #6a6c6f;
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0.04em;

        &:last-child {
          margin-bottom: 0;
        }
      }
      .green {
        color: #007c16;
        font-weight: bolder;
        font-size: 20px;
      }
      .model-image {
        width: 150px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        object-fit: contain;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
    .section.last {
      border-right: none;
    }
  }
  .channels-card::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  .channels-card::-webkit-scrollbar-thumb {
    background-color: #e1e1e9;
    border-radius: 3px;
  }

  .channels-card::-webkit-scrollbar-thumb:hover {
    background-color: #b1b1b9;
  }

  .channels-card::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  .channels-card::-webkit-scrollbar-track:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.filter-channel-details-div {
  padding: 10px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  .filter-div {
    button {
      width: 100px;
      background-color: #007c16;
      color: #fff;
      border: none;
      border-radius: 2px;
      padding: 5px;
      @media (max-width: 450px) {
        width: 100%;
      }
    }
    @media (max-width: 450px) {
      width: 100%;
      margin-bottom: 10px;
    }
  }
  @media (max-width: 450px) {
    flex-direction: column;
    justify-content: center;
  }
}
