$GREENCOLOR: #007c16;
$YELLOWCOLOR: #f2b92c;
$DANGERCOLOR: #dc3545;
$viewport-height: 1vh;

.campaignComponents {
  /* Styling for tabs container */
  .tabs-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 24px;

    @media (max-width: 768px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .tab {
      flex: 1;
      text-align: center;
      padding: 12px 0;
      margin: 0 48px;
      cursor: pointer;
      font-weight: bold;
      transition: all 0.3s ease;
      text-transform: uppercase;
      letter-spacing: 0.09em;
      background-color: #fff;

      @media (max-width: 768px) {
        margin: 0;
      }

      &.active {
        border-bottom: 4px solid #007c16;
        color: #007c16;

        @media (max-width: 768px) {
          border-bottom: 0;
          border-left: 6px solid #007c16;
        }
      }

      &:hover {
        color: #007c16;
      }
    }
  }

  .dropdown-toggle::after {
    display: none !important;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range,
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected,
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background-color: $GREENCOLOR !important;
    color: #fff !important;
  }

  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover,
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item:hover {
    background-color: $GREENCOLOR !important;
    color: #fff !important;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list {
    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #e1e1e9;
      border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #b1b1b9;
    }

    &::-webkit-scrollbar-track {
      background-color: #f1f1f1;
    }

    &::-webkit-scrollbar-track:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  .browseIcon {
    display: block;
    width: 100%;
    background: $GREENCOLOR;
    color: #fff;
    border: none;
    outline: none;
    padding: 5px;
    border-radius: 4px;
  }

  .iconBox {
    padding: 20px;
    font-size: 14px;
    border-radius: 3px;
    border: 1px solid #eaeaea;
    z-index: 1;
    background-color: #c71a1a;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    width: 100%;
    height: 100%;
  }

  .fs-14 {
    font-size: 14px;
  }

  .fs-15 {
    font-size: 15px;
  }

  .fs-16 {
    font-size: 16px;
  }

  .fs-18 {
    font-size: 18px;
  }

  .grey-btn {
    border: 1px solid #eaeaea;
    border-radius: 2px;
    padding: 8px;
    min-width: 130px;
    background-color: #6a6a6a;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .editBtn {
    border: 1px solid #eaeaea;
    border-radius: 2px;
    margin-left: 5px;
    padding: 8px;
    min-width: 110px;
    background-color: $GREENCOLOR;
    color: #fff;
  }

  .deleteBtn {
    border: 1px solid #eaeaea;
    border-radius: 2px;
    margin-left: 5px;
    padding: 8px;
    min-width: 110px;
    background-color: $DANGERCOLOR;
    color: #fff;
  }

  // switch
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    margin-bottom: 10px;
  }

  .switch.switch-apply {
    width: 60px;
    height: 34px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;
  }

  .slider.slider-apply {
    border-radius: 34px;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }

  .slider.slider-apply::before {
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
  }

  .switch input:checked + .slider {
    background-color: #007c16;
  }

  .switch input:focus + .slider {
    box-shadow: 0 0 1px #007c16;
  }

  .switch input:checked + .slider:before {
    transform: translateX(26px);
  }

  .switch.switch-apply input:checked + .slider.slider-apply:before {
    transform: translateX(26px);
  }

  // table
  .campaignTable {
    width: 100%;
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;

    tr {
      th,
      td {
        white-space: nowrap;
      }
    }

    thead {
      background-color: #fbfbfb;

      tr {
        th {
          font-weight: bold;
        }
      }
    }

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #e1e1e9;
      border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #b1b1b9;
    }

    &::-webkit-scrollbar-track {
      background-color: #f1f1f1;
    }

    &::-webkit-scrollbar-track:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  // table
  .text-center {
    text-align: center;
  }

  .gap-20 {
    gap: 20px;
  }

  .gap-8 {
    gap: 8px;
  }

  .gap-10 {
    gap: 10px;
  }

  .max-150 {
    max-width: 150px;
  }

  // .addCampaignBtn,
  .updateCampaignBtn {
    background-color: $GREENCOLOR;
    border: none;
    border-radius: 2px;
    color: #fff;
    margin-right: 15px;
    outline: none;
    padding: 8px 12px;
  }

  .flexRow {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    .campaignCard {
      background-color: #fff;
      flex: 0 0 auto;
      width: calc(50% - 10px);

      .campaignTitle {
        color: #6a6c6f;
        font-size: 36px;
        font-weight: 600;
        cursor: pointer;
      }

      .campaignTitle:hover {
        color: #007c16;
      }

      .flexBetweenCenter {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
      }

      .threeBox {
        padding: 15px;
        background-color: #fbfbfb;
        display: flex;
        justify-content: center;

        .box {
          text-align: center;
          padding-left: 15px;
          padding-right: 15px;

          &:not(:last-child) {
            border-right: 1px solid #e7e7e7;
          }

          h5 {
            color: #afb2b3;
            font-size: 16px;
            font-weight: 600;
            text-align: center;
          }

          span {
            color: #6a6c6f;
            font-weight: 600;
          }
        }
      }

      .status {
        align-items: center;
        border-radius: 2px;
        display: flex;
        justify-content: center;
        padding: 5px;
        width: 75px;
      }

      .campaignDiscription {
        color: #6a6c6f;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 0;
      }

      .footer-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
        @media (max-width: 1280px) {
          flex-direction: column;
          div {
            width: 100%;
            button {
              width: 100%;
              margin: 3px 0;
            }
          }
        }
      }
    }
  }

  .borderTopGreen {
    border-top: 3px solid $GREENCOLOR;
  }

  .borderTopYellow {
    border-top: 3px solid $YELLOWCOLOR;
  }

  .textGreen {
    color: $GREENCOLOR;
  }

  .bgGreen {
    background-color: $GREENCOLOR;
  }

  .bgYellow {
    background-color: $YELLOWCOLOR;
  }

  .pagination {
    .page-link {
      color: $GREENCOLOR;
    }

    .active {
      .page-link {
        background-color: $GREENCOLOR;
      }
    }
  }

  // modal
  .modalOverlay {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;

    .modalSubmitBtn {
      align-items: center;
      border: 2px solid $GREENCOLOR;
      background-color: $GREENCOLOR;
      color: #fff;
      display: flex;
      justify-content: center;
      outline: none;
      padding: 8px;
      min-width: 110px;
      border-radius: 2px;
    }

    .modalCancelBtn {
      align-items: center;
      color: #fff;
      display: flex;
      justify-content: center;
      outline: none;
      padding: 8px;
      min-width: 110px;
      background-color: #6c757d;
      border: 2px solid #6c757d;
      border-radius: 2px;
    }

    .modalContent {
      background-color: #fff;
      border-radius: 4px;
      max-width: 1200px;
      width: 100%;
      height: fit-content;
      max-height: calc(100dvh - 50px);
      display: flex;
      flex-direction: column;

      .nodesContainer {
        max-height: 400px;
        overflow-y: auto;

        &::-webkit-scrollbar {
          width: 6px;
          height: 6px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #e1e1e9;
          border-radius: 3px;
        }

        &::-webkit-scrollbar-thumb:hover {
          background-color: #b1b1b9;
        }

        &::-webkit-scrollbar-track {
          background-color: #f1f1f1;
        }

        &::-webkit-scrollbar-track:hover {
          background-color: rgba(0, 0, 0, 0.1);
        }
      }

      .modalHeader {
        background-color: $GREENCOLOR;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;
        color: #fff;

        .close {
          all: unset;
          cursor: pointer;
        }
      }

      .modalBody {
        padding: 20px;
        overflow-y: auto;
        flex-grow: 1;
        position: relative;

        .select-icon {
          font-weight: 500;
          padding: 8px 0;
          border-bottom: 1px solid #e4e4e4;
        }

        /* Customizing the scrollbar */
        &::-webkit-scrollbar {
          width: 6px;
          height: 6px;
        }

        &::-webkit-scrollbar-track {
          background-color: #f1f1f1;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #e1e1e9;
          border-radius: 3px;
        }

        &::-webkit-scrollbar-thumb:hover {
          background-color: #b1b1b9;
        }

        &::-webkit-scrollbar-track:hover {
          background-color: rgba(0, 0, 0, 0.1);
        }

        .createLoader {
          position: fixed;
          background-color: rgba(0, 0, 0, 0.3);
          z-index: 2;
          inset: 0;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .icons-group label {
          cursor: pointer;
          padding: 8px;
        }

        .icons-group {
          display: flex;
          justify-content: center;
        }

        label.selected .radio-icon {
          border: 2px solid #0077b6;
        }

        .radio-icon {
          width: 48px;
          height: 48px;
          padding: 4px;
          border-radius: 4px;
        }

        .form-control {
          &:focus {
            border-color: $GREENCOLOR;
            box-shadow: none;
          }
        }

        .form-check-input:checked {
          border-color: $GREENCOLOR;
        }

        .form-check-input[type="radio"]:checked:after,
        .form-check-input[type="checkbox"]:checked {
          border-color: $GREENCOLOR;
          background-color: $GREENCOLOR;
        }

        .input-group-text {
          background-color: #dbdbdb;
        }

        table {
          th,
          td {
            padding: 0.7rem 1rem;
          }
        }
      }
    }
  }

  .per-page-and-pagination {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 35px;
    padding: 20px 0 8px 0;
    z-index: 0;

    .per-page {
      display: flex;
      align-items: center;
      margin-bottom: -2px;
      margin-right: 25px;
    }
  }

  .deleteModal {
    .modalContent {
      max-width: 500px;
    }

    .modalBody {
      height: auto !important;
      overflow-y: auto !important;
    }
  }

  .filterCampaign {
    .backdrop {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 1;
    }

    .drawer {
      position: fixed;
      background-color: #fff;
      top: 0;
      right: 0;
      width: 100%;
      max-width: 500px;
      height: 100vh;
      overflow-y: auto;
      transition: right 0.3s ease;
      z-index: 2;

      /* Customizing the scrollbar */
      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }

      &::-webkit-scrollbar-track {
        background-color: #f1f1f1;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #e1e1e9;
        border-radius: 3px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background-color: #b1b1b9;
      }

      &::-webkit-scrollbar-track:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }

    .drawerHeader {
      background-color: $GREENCOLOR;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .close {
        border: none;
        outline: none;
        background: none;
        color: #fff;
      }
    }

    .resetBtn {
      border: 2px solid;
      border-radius: 2px;
      color: #fff;
      flex: 1 1;
      outline: none;
      padding: 8px;
      background-color: #6a6c6f;
      border-color: #6a6c6f;
    }

    .submitBtn {
      border: 2px solid;
      border-radius: 2px;
      color: #fff;
      flex: 1 1;
      outline: none;
      padding: 8px;
      background-color: $GREENCOLOR;
      border-color: $GREENCOLOR;
      margin-left: 10px;
    }

    .form-control {
      &:focus {
        border-color: $GREENCOLOR;
        box-shadow: none;
      }
    }
  }

  // media query
  @media (max-width: 992px) {
    .flexRow {
      .campaignCard {
        width: 100%;
      }
    }
  }

  @media (max-width: 1200px) {
    .modalContent {
      min-height: 100dvh;
      max-height: 100dvh;
    }
  }
}

.react-datepicker__close-icon::after {
  background-color: #6a6c6f !important;
}

.qrlink-anchor,
.qrlink-anchor:hover {
  color: #dbdbdb;
}

.qrlink-anchor:hover {
  text-decoration: underline;
}

.list-container {
  /* Customizing the scrollbar */
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #e1e1e9;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #b1b1b9;
  }

  &::-webkit-scrollbar-track:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.cursor-pointer {
  display: flex;
  justify-content: center;
  align-items: center;

  label {
    cursor: pointer;
  }
}

.configure-amounts-btn {
  background-color: transparent;
  color: #007c16;
  border: 1px solid;
  outline: none;
  padding: 6px 12px;
  width: 100%;
  border-radius: 3px;
  text-align: left;
}

.notes-div.nodes {
  div {
    max-height: 350px;
    flex-wrap: wrap;
  }
}

.banner-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .banner-div {
    width: 88px;
    height: 88px;
    border-radius: 50%;
    border: 1px solid #e4e4e4;
    cursor: pointer;
    position: relative;
    margin-bottom: 6px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }

    .edit-icon-cirle {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      background-color: #f8f8f8;
      border: 1px solid #007c16;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  span {
    font-size: 13px;
    color: #6a6a6a;
    cursor: pointer;
  }

  span:hover {
    color: #007c16;
    text-decoration: underline;
  }
}

.banner-img-container {
  width: 100%;
  height: 400px;
  border: 1px solid #e4e4e4;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.mdb-cancel-btn {
  background-color: #696a63 !important;
  width: 100%;
  box-shadow: none !important;
}

.mdb-delete-btn {
  background-color: #dc3545 !important;
  width: 100%;
  margin: 8px 0;
  box-shadow: none !important;
}
