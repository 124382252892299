.middle-area {
  width: 100%;
  height: 100%;
  background-color: #f1f3f6;
  border: 1px solid #eaeaea;
  padding: 25px 30px 40px 30px;
  min-width: 320px;
  overflow-y: auto;
  overflow-x: hidden;
}

@media (max-width: 1024px) {
  .middle-area {
    padding: 15px;
  }
}

.middle-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.middle-content.flex-start {
  justify-content: flex-start;
  align-items: flex-start;
}

.middle-content.height {
  height: 100%;
}

.middle-content .full-loading-screen {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.middle-content h6 {
  font-size: 18px;
  font-weight: 600;
  color: #6a6c6f;
  font-weight: 600;
  padding: 10px 4px;
  transition: all 0.3s;
  border: 1px solid transparent;
}

.dashboard-row {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.dashboard-row .row-boxes-left {
  width: 50%;
  height: 180px;
  margin-right: 15px;
  display: flex;
}

.row-boxes-left .row-box.left-most,
.row-boxes-right .row-box.left-most {
  margin-right: 15px;
}

.dashboard-row .row-boxes-right {
  width: 50%;
  height: 180px;
  display: flex;
}

.dashboard-row .row-box {
  width: 50%;
  background: #fff;
  border: 1px solid #eaeaea;
  border-radius: 2px;
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.icon-with-text {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  color: #6a6c6f;
}

.icon-with-text span {
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
}

.h1 {
  font-size: 36px;
  font-weight: 200;
  line-height: 1.1;
  color: #007c16;
  text-align: center;
  white-space: nowrap;
}

.row-box .social-users-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.social-users-div .social-users-heading {
  font-size: 11px;
  font-weight: 600;
  color: #6a6c6f;
  margin-bottom: 3px;
}

.social-users-div .progress-bar-div {
  width: 100%;
  height: 15px;
  background-color: #f5f5f5;
  display: flex;
  border-radius: 2px;
}

.progress-bar-div .progress-bar-colored {
  background-color: #007c16;
  border-radius: 2px;
}

@media (max-width: 1280px) {
  .dashboard-row {
    flex-direction: column;
  }

  .dashboard-row .row-boxes-left,
  .dashboard-row .row-boxes-right {
    width: 100%;
  }

  .dashboard-row .row-boxes-left {
    margin-right: 0;
    margin-bottom: 15px;
  }
}

@media (max-width: 767px) {
  .dashboard-row .row-boxes-left,
  .dashboard-row .row-boxes-right {
    flex-direction: column;
    height: auto;
  }

  .dashboard-row .row-boxes-left .row-box,
  .dashboard-row .row-boxes-right .row-box {
    width: 100%;
  }

  .dashboard-row .row-boxes-left .row-box.left-most,
  .dashboard-row .row-boxes-right .row-box.left-most {
    margin: 0 0 15px 0;
  }
}

/* row 2 */
.dashboard-row-left,
.dashboard-row-right {
  width: 50%;
  height: 380px;
}

.dashboard-row-left.flex-2{
  width: 75%;
}

.dashboard-row-left.flex-1{
  width: 25%;
}

.dashboard-row-left {
  margin: 0 15px 0 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  border: 1px solid #eaeaea;
  background-color: #fff;
  border-radius: 2px;
}

.dashboard-row-left.full-width {
  width: 100%;
  margin: 0;
  height: 390px;
}

.dashboard-row-left.full-width.special-height-case{
  height: fit-content;
}

.dashboard-row-left .graph-text {
  font-size: 14px;
  color: #6a6c6f;
  font-weight: 600;
  white-space: nowrap;
  margin-bottom: 10px;
}

.graph-container {
  width: 100%;
  flex: 1;
  min-height: 250px;
  max-height: 300px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.graph-container:before {
  content: "";
  display: block;
  padding-top: 60%;
}

.graph-container canvas {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 1280px) {
  .dashboard-row-left,
  .dashboard-row-right {
    width: 100%;
    height: auto;
  }

  .dashboard-row-left.flex-2{
    width: 100%;
  }
  
  .dashboard-row-left.flex-1{
    width: 100%;
    max-height: 380px;
  }

  .dashboard-row-left {
    margin: 0 0 15px 0;
  }

  .dashboard-row-left.full-width {
    height: auto;
  }
}

.dashboard-row-right {
  display: flex;
}

.dashboard-row-right.background-white {
  flex-direction: column;
  padding: 20px;
  border: 1px solid #eaeaea;
  background-color: #fff;
  border-radius: 2px;
}

.dashboard-row-right .row-right-col {
  width: 50%;
  height: 100%;
  margin: 0 15px 0 0;
  display: flex;
  flex-direction: column;
}

.dashboard-row-right .row-right-col.right-most {
  margin-right: 0;
}

.col-box {
  width: 100%;
  background: #fff;
  border: 1px solid #eaeaea;
  border-radius: 2px;
  padding: 20px;
  flex: 1;
  margin: 0 0 15px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.col-box.bottom-most {
  margin-bottom: 0;
}

@media (max-width: 1280px) {
  .dashboard-row-right {
    flex-direction: column;
  }

  .dashboard-row-right .row-right-col {
    width: 100%;
    flex-direction: row;
    margin-right: 0;
    margin-bottom: 15px;
  }

  .dashboard-row-right .row-right-col.right-most {
    margin-bottom: 0;
  }

  .col-box {
    margin: 0 15px 0 0;
  }

  .col-box.bottom-most {
    margin-right: 0;
  }
}

@media (max-width: 767px) {
  .dashboard-row-right .row-right-col {
    flex-direction: column;
  }

  .col-box {
    margin: 0 0 15px 0;
  }

  .col-box.bottom-most {
    margin-bottom: 0;
  }
}

/* popup css */
.text-and-filter-div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 3px;
  margin-bottom: 15px;
}

.text-and-filter-div span {
  font-size: 14px;
  font-weight: 600;
  color: #6a6c6f;
  white-space: nowrap;
}

.popup {
  padding: 20px;
  font-size: 14px;
  border-radius: 3px;
  border: 1px solid #eaeaea;
  position: absolute;
  top: 24px;
  right: 6px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  visibility: hidden;
  opacity: 0;
}

.popup.open {
  visibility: visible;
  opacity: 1;
  animation-name: flipInX;
  animation-duration: 1s;
  animation-fill-mode: both;
}

@keyframes flipInX {
  0% {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transition-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transition-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  100% {
    transform: perspective(400px);
  }
}

.popup h4 {
  font-weight: 600;
  font-size: 14px;
  width: 100%;
  text-align: left;
  color: #6a6c6f;
  border-bottom: 1px solid #eaeaea;
  padding: 5px;
  letter-spacing: 0.09em;
}

.popup-filter-btn-div {
  width: 100%;
  margin-top: 5px;
}

.popup-filter-btn-div button {
  width: 100%;
  outline: none;
  border: none;
  background-color: #007c16;
  color: #fff;
  border-radius: 3px;
  padding: 5px 0;
}

.month-summary-filter-form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.month-summary-filter-form .filter-form-col {
  margin: 5px 0 5px 0;
  width: 100%;
}

.checkboxes-div {
  display: flex;
  align-items: center;
  margin: 5px 0;
  padding: 5px;
}

.checkboxes-div label {
  margin-right: 10px;
}

@media (max-width: 1024px) {
  .checkboxes-div {
    flex-direction: column;
    align-items: flex-start;
  }
}

.select-div {
  display: flex;
  width: 100%;
  margin: 5px 0 5px 0;
}

@media (max-width: 540px) {
  .select-div {
    flex-direction: column;
  }
}

.select-div .select-col {
  width: 100%;
  margin: 5px;
}

/* date picker css */
.react-date-picker {
  width: 100%;
  background-color: #fff !important;
  margin-bottom: 10px !important;
}

.react-date-picker__inputGroup__input {
  padding: 0 5px !important;
}

.react-date-picker input {
  outline: none !important;
}

.react-date-picker__button:enabled:hover .react-date-picker__button__icon,
.react-date-picker__button:enabled:focus .react-date-picker__button__icon {
  stroke: #007c16 !important;
}

.react-calendar__tile--active {
  background: #007c16 !important;
}

span[class*="-picker__inputGroup__divider"] {
  padding: 10px;
}

div[class*="-picker__wrapper"] {
  background: white;
  border: 0;
  border-radius: 2px;
  font-size: 1em;
  border: 1px solid #eaeaea;
}

/* no data found div */
.no-data-found-div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-data-found-div img {
  width: 100px;
  height: auto;
  cursor: pointer;
}

/* table */
.custom-table-container {
  width: 100%;
  flex: 1;
  min-height: 250px;
  max-height: 390px;
  overflow: auto;
}

.custom-table-container.special-height-case{
  min-height: fit-content;
}

.custom-table-container::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.custom-table-container::-webkit-scrollbar-thumb {
  background-color: #e1e1e9;
  border-radius: 3px;
}

.custom-table-container::-webkit-scrollbar-thumb:hover {
  background-color: #b1b1b9;
}

.custom-table-container::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.custom-table-container::-webkit-scrollbar-track:hover {
  background-color: rgba(
    0,
    0,
    0,
    0.1
  );
}

.custom-table-container.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.thead {
  background-color: #fbfbfb;
}

.thead tr th {
  font-weight: 600;
  font-size: 14px;
  white-space: nowrap;
}

.progress-trail {
  background-color: #e4e4e4;
  width: 100%;
  height: 6px;
  border-radius: 7px;
}

.table-progressbar {
  border-radius: 7px;
  height: 6px;
}

.spans-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spans-container span {
  margin: 0 7px 0 7px;
  padding: 6px 4px;
  border-radius: 3px;
}

.spans-container span:hover,
.spans-container span.channels,
.spans-container span.year,
.spans-container span.month,
.spans-container span.day {
  background-color: #d3ecc8;
}

.spans-container span:hover span,
.spans-container span.channels span,
.spans-container span.year span,
.spans-container span.month span,
.spans-container span.day span {
  color: #007c16;
}

.popup.responsive {
  top: 40px;
}

@media (max-width: 1300px) {
  .text-and-filter-div.responsive {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .text-and-filter-div.responsive span {
    white-space: normal;
  }

  .spans-container {
    margin-top: 10px;
  }

  .popup.responsive {
    top: 90px;
  }
}
