.configuration-table {
  table-layout: auto; /* Let the table automatically adjust column widths */
  width: 100%; /* Make the table width 100% of the container */
}

.long-description {
  word-wrap: break-word; /* Ensure words break within the cell */
  white-space: pre-wrap; /* Preserve whitespace and line breaks */
  max-width: 300px; /* Set a maximum width for the cell */
}

.emails-cell {
  max-height: 100px; /* Set a fixed height for the emails cell */
  overflow-y: auto; /* Enable vertical scrolling */
}

.email-item {
  margin: 0; /* Remove default margin from <p> */
  padding: 2px 0; /* Add some padding for better readability */
}

.email-list-container {
  max-height: 200px;
  overflow-y: auto;
  padding-top: 0.33em;
  padding-bottom: 0.33em;
  padding-left: 0.75em;
  padding-right: 0.75em;
  border: 1px solid #bdbdbd;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  color: #4f4f4f;
  border-radius: 0.25rem;
  .configuration-email-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 7px 0;
  }
}

// webkit browsers
.email-list-container::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.email-list-container::-webkit-scrollbar-thumb {
  background-color: #e1e1e9;
  border-radius: 3px;
}

.email-list-container::-webkit-scrollbar-thumb:hover {
  background-color: #b1b1b9;
}

.email-list-container::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.email-list-container::-webkit-scrollbar-track:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
