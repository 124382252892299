@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.wrapper {
  width: 100%;
  overflow: hidden;
}

.dashboard {
  width: 100%;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

@media (max-width: 1024px) {
  .wrapper {
    overflow-x: auto;
  }

  .dashboard.toggled {
    width: fit-content;
    min-width: 100%;
  }
}

.content-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
  overflow-y: auto;
  overflow-x: hidden;
}

/* cache loading */
.cache-loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100dvh;
  background-color: rgba(255, 255, 255, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.custom-ok-button {
  background-color: #007c16 !important;
  border-color: #007c16 !important;
  outline-color: #007c16 !important;
  box-shadow: none !important;
}

.custom-danger-button {
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
  outline-color: #dc3545 !important;
  box-shadow: none !important;
}

.custom-datepicker-zindex {
  z-index: 100001 !important;
}

/* Dropdown container */
.MuiAutocomplete-listbox {
  max-height: 250px !important;
  overflow-y: auto;
}

.MuiAutocomplete-popper {
  z-index: 100001 !important; /* This targets the container of the dropdown */
}

/* Custom scrollbar styles */
.MuiAutocomplete-listbox::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.MuiAutocomplete-listbox::-webkit-scrollbar-thumb {
  background-color: #e1e1e9;
  border-radius: 3px;
}

.MuiAutocomplete-listbox::-webkit-scrollbar-thumb:hover {
  background-color: #b1b1b9;
}

.MuiAutocomplete-listbox::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.MuiAutocomplete-listbox::-webkit-scrollbar-track:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.list-div {
  padding: 12px 8px;
  margin: 4px 0;
  font-size: 16px;
  color: #333;
}

.list-div:hover {
  background-color: rgba(0, 124, 22, 0.2);
}

.list-div[aria-selected="true"] {
  background-color: #007c16;
  color: #fff;
}
