// table
.transactions-table {
  width: 100%;
  max-height: 550px;
  overflow: auto;
  white-space: nowrap;
  position: relative;
  .thead {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 10001;
  }
  .donor-hyper-link {
    color: #4f4f4f;
    font-weight: 400 !important;
    margin-bottom: 0.25rem !important;
  }
  .donor-hyper-link:hover {
    color: #007c16;
  }
  .icons-td {
    .actions-icon-container {
      display: flex;
      span {
        color: #007c16;
        margin-right: 15px;
        cursor: pointer;
      }
      .loader-span {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

// webkit browsers
.transactions-table::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.transactions-table::-webkit-scrollbar-thumb {
  background-color: #e1e1e9;
  border-radius: 3px;
}

.transactions-table::-webkit-scrollbar-thumb:hover {
  background-color: #b1b1b9;
}

.transactions-table::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.transactions-table::-webkit-scrollbar-track:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

// transaction details
.transaction-details {
  padding: 10px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    .merchant {
      h4 {
        color: #6a6c6f;
      }
      h5 {
        color: #007c16;
      }
    }
    .amount {
      font-size: 32px;
    }
  }

  .content {
    padding-top: 10px;
    .detail {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;
      font-size: 18px;
      padding: 15px 10px 10px 10px;
      .key {
        color: #6a6c6f;
      }
      h6 {
        font-weight: 600;
      }
      &:nth-child(odd) {
        background-color: #fafafa;
      }
    }
  }

  .card-details {
    margin-top: 20px;
    display: flex;
    align-items: center;
  }

  .card-image {
    width: 64px;
    height: auto;
    margin-right: 15px;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .notes-div {
    padding: 15px 10px 10px 10px;
    div {
      background-color: #fafafa;
      padding: 15px 10px 10px 10px;
      min-height: 75px;
      max-height: 150px;
      overflow-y: auto;
      display: flex;
      align-items: center;
    }
    // webkit browsers
    div::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    div::-webkit-scrollbar-thumb {
      background-color: #e1e1e9;
      border-radius: 3px;
    }

    div::-webkit-scrollbar-thumb:hover {
      background-color: #b1b1b9;
    }

    div::-webkit-scrollbar-track {
      background-color: #f1f1f1;
    }

    div::-webkit-scrollbar-track:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
    h5 {
      font-size: 18px;
      font-weight: 600;
      color: #6a6c6f;
    }
  }
}

// resides in transaction details
.close-btn-div {
  padding: 10px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  button {
    background-color: #007c16;
    outline: none;
    border: none;
    border-radius: 2px;
    padding: 10px 14px;
    color: #fff;
    width: 110px;
  }
}

// pagination
.transactions-pagination {
  width: 100%;
  .per-page-and-pagination {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    .per-page {
      margin-bottom: 20px;
    }
  }
}

.add-and-filter-btns-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  .action-btns-div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    .action-buttons {
      width: fit-content;
      margin: 0 5px;
      padding: 8px 10px;
      border-radius: 2px;
      outline: none;
      background-color: #007c16;
      color: #fff;
      border: none;
      @media (max-width: 768px) {
        margin: 5px 0;
      }
    }
  }
  .filter-and-reset-btns-div {
    display: flex;
    margin-left: 5px;
    @media (max-width: 768px) {
      margin-left: 0;
      margin-top: 5px;
    }
    .filter-media-modal-btn {
      background-color: #fff;
      color: #6a6c6f;
      border: 2px solid #eaeaea;
      border-radius: 2px;
      padding: 0 10px;
      height: 44px;
      display: flex;
      align-items: center;
      .react-spinner-metro {
        margin: 0 auto;
      }
    }
    .filter-media-modal-btn.selected {
      background-color: #007c16;
      color: #fff;
      border: 0;
    }
    .filter-media-modal-btn.reset {
      margin-left: 10px;
    }
  }
  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.tender-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .custom-radio {
    display: flex;
    align-items: center;

    label {
      cursor: pointer;
      display: flex;
      margin: 10px 0;
      .input-radio {
        box-shadow: 0px 0px 0px 1px #6d6d6d;
        font-size: 3em;
        width: 25px;
        height: 25px;
        margin-right: 7px;

        border: 2px solid #fff;
        background-clip: border-box;
        border-radius: 50%;
        appearance: none;
        transition: background-color 0.3s, box-shadow 0.3s;
      }
      .input-radio:checked {
        box-shadow: 0px 0px 0px 1px #007c16;
        background-color: #007c16;
      }
    }
  }
}

.tender-row.card {
  box-shadow: none;
  border-radius: 0;
  flex-direction: column;
  font-size: 13px;
  padding: 10px;
  .custom-radio {
    label {
      .input-radio {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.email-input-container {
  display: flex;
  align-items: center;
  position: relative;
  .edit-media-inputs.no-borders-right {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .add-button {
    background-color: #6a6c6f;
    padding: 5px 10px;
    font-size: 16px;
    cursor: pointer;
    border: 1px solid #6a6c6f;
    color: #fff;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
  .suggestions-popup {
    position: absolute;
    top: 40px;
    width: 100%;
    max-height: 250px;
    overflow-y: auto;
    border: 1px solid #ccc;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 10000;
    .suggestion-item {
      padding: 8px 16px;
      cursor: pointer;
      transition: background-color 0.2s;
      .suggestion-name {
        font-size: 13px;
        margin-top: 3px;
      }
    }
    .suggestion-item:hover {
      background-color: #f0f0f0;
    }
  }
  .suggestions-popup.address {
    max-height: 190px;
  }
}

// customizing the scrollbar of popup
.suggestions-popup::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.suggestions-popup::-webkit-scrollbar-thumb {
  background-color: #e1e1e9;
  border-radius: 3px;
}

.suggestions-popup::-webkit-scrollbar-thumb:hover {
  background-color: #b1b1b9;
}

.suggestions-popup::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.suggestions-popup::-webkit-scrollbar-track:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected,
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #007c16 !important;
  color: #fff !important;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item:hover {
  background-color: #007c16 !important;
  color: #fff !important;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list {
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #e1e1e9;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #b1b1b9;
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  &::-webkit-scrollbar-track:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.checkbox-container {
  margin-top: 5px;
  max-height: 170px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  .checkbox-div {
    width: 100%;
    padding: 8px 16px;
    transition: background-color 0.2s;
  }
  .checkbox-div:hover {
    background-color: rgba(0, 124, 22, 0.2);
  }
}

.form-check-input[type="checkbox"]:checked {
  background-image: none !important;
  background-color: #007c16 !important;
  border-color: #007c16 !important;
}

.checkbox-container::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.checkbox-container::-webkit-scrollbar-thumb {
  background-color: #e1e1e9;
  border-radius: 3px;
}

.checkbox-container::-webkit-scrollbar-thumb:hover {
  background-color: #b1b1b9;
}

.checkbox-container::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.checkbox-container::-webkit-scrollbar-track:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

// wallet
.wallet-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .wallet-card.payments-card {
    flex: 0 0 calc(50% - 10px);
    margin: 10px 0px;
    height: fit-content;
    @media (max-width: 768px) {
      flex: 0 0 100%;
      margin: 0;
      margin-bottom: 20px;
    }
    h6 {
      font-size: 24px;
    }
    .chip-img {
      width: 90px;
    }
    h4 {
      font-size: 48px;
    }
    .card-brand-image {
      width: 100px;
    }
  }
  .wallet-card {
    flex: 0 0 calc(50% - 40px);
    height: 300px;
    background-color: #fff;
    margin: 20px;
    padding: 10px 10px 10px 0;
    border: 1px solid #eaeaea;
    border-radius: 2px;
    overflow: hidden;
    cursor: pointer;
    @media (max-width: 768px) {
      flex: 0 0 100%;
      margin: 0;
      margin-bottom: 20px;
    }
    h6 {
      font-weight: 600;
      text-align: right;
    }
    .chip-img {
      width: 70px;
      height: auto;
      margin-left: 50px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    h4 {
      font-size: 32px;
      color: #6a6c6f;
      letter-spacing: 0.09em;
      margin-left: 15px;
      white-space: nowrap;
    }
    .name-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .valid-thru {
        .expiry-date {
          display: flex;
          // justify-content: space-between;
          margin-left: 15px;
        }
      }
      .card-brand-image {
        width: 80px;
        height: auto;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .wallet-action-buttons-div {
      width: 100%;
      padding: 20px 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .wallet-action-btns {
        width: fit-content;
        padding: 7px 21px;
        border: none;
        outline: none;
        margin: 7px;
        border: none;
        border: 2px solid;
      }
      .wallet-action-btns.charge {
        background-color: #fff;
        color: #007c16;
        border-color: #007c16;
      }
      .wallet-action-btns.edit {
        background-color: #007c16;
        color: #fff;
        border-color: #007c16;
      }
      .wallet-action-btns.delete {
        background-color: #dc3545;
        color: #fff;
        border-color: #dc3545;
      }
    }
  }
}

.update-widgets-modal-body.select-wallet {
  min-height: 400px;
  position: relative;
  .wallet-loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .add-new-card-div {
    width: 100%;
    margin: 20px 0 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .add-new-card-btn {
      border: 2px solid #007c16;
      border-radius: 2px;
      padding: 8px 16px;
      background-color: #fff;
      color: #007c16;
    }
  }
}
