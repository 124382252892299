.taxreceipt-summary-row {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 25px;
  .taxreceipt-summary-box {
    width: 100%;
    height: fit-content;
    margin-bottom: 20px;
    padding: 20px;
    background-color: #fff;
    border-radius: 2px;
    border: 1px solid #eaeaea;
    position: relative;
    overflow: hidden;
    cursor: pointer;

    @media (min-width: 768px) {
      width: calc(50% - 10px);
      margin-right: 10px;
    }

    @media (min-width: 1400px) {
      width: calc(25% - 15px);
      margin-right: 15px;
    }

    h4 {
      color: #6a6c6f;
      font-size: 18px;
      font-weight: 600;
      span {
        margin-left: 5px;
        cursor: pointer;
      }
    }
    p {
      color: #007c16;
      font-weight: 600;
      margin-top: 20px;
      font-size: 20px;
    }
    img {
      width: 60px;
      height: 60px;
      position: absolute;
      top: 10px;
      right: 15px;
    }
    .summary-progress-bar-div {
      width: 100%;
      background-color: #f5f5f5;
      position: absolute;
      bottom: 0;
      left: 0;
      height: 8px;
      border-radius: 2px;
      .summary-progress-bar {
        height: 100%;
        background-color: #007c16;
      }
    }
  }
  .taxreceipt-summary-box.states {
    @media (min-width: 992px) {
      width: calc(33.33% - 15px);
      margin-right: 15px;
    }
  }
}

.submit-and-cancel-div.tax-receipts {
  justify-content: space-between;
  @media (max-width: 450px) {
    flex-direction: column;
    button {
      width: 100%;
      margin: 5px 0;
    }
  }
  .send-me-email-sample-div {
    display: flex;
    button {
      width: fit-content;
      margin: 0 5px;
    }
    @media (max-width: 450px) {
      width: 100%;
      flex-direction: column;
      button {
        width: 100%;
        margin: 5px 0;
      }
    }
  }
}

.ql-container.ql-snow {
  height: 250px !important;
  .ql-editor {
    height: 100% !important;
    overflow-y: auto !important;
  }
  .ql-editor::-webkit-scrollbar {
    width: 6px !important;
    height: 6px !important;
  }
  .ql-editor::-webkit-scrollbar-thumb {
    background-color: #e1e1e9 !important;
    border-radius: 3px !important;
  }

  .ql-editor::-webkit-scrollbar-thumb:hover {
    background-color: #b1b1b9 !important;
  }

  .ql-editor::-webkit-scrollbar-track {
    background-color: #f1f1f1 !important;
  }

  .ql-editor::-webkit-scrollbar-track:hover {
    background-color: rgba(0, 0, 0, 0.1) !important;
  }
}
