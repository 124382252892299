.schedule-summary-row {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 25px;
  .summary-box {
    width: 100%;
    height: fit-content;
    margin-bottom: 20px;
    padding: 20px;
    background-color: #fff;
    border-radius: 2px;
    border: 1px solid #eaeaea;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    @media (min-width: 540px) {
      width: calc(50% - 10px);
      margin-right: 10px;
    }

    @media (min-width: 768px) {
      width: calc(33.33% - 5px);
      margin-right: 5px;
    }

    @media (min-width: 1400px) {
      width: calc(25% - 10px);
      margin-right: 10px;
    }

    @media (min-width: 1600px) {
      width: calc(16.65% - 5px);
      margin-right: 5px;
    }

    h4 {
      color: #6a6c6f;
      font-size: 18px;
      font-weight: 600;
      span {
        margin-left: 5px;
        cursor: pointer;
      }
    }
    p {
      color: #007c16;
      font-weight: 600;
      margin-top: 20px;
      font-size: 20px;
    }
    img {
      width: 60px;
      height: 60px;
      position: absolute;
      top: 10px;
      right: 15px;
    }
    .summary-progress-bar-div {
      width: 100%;
      background-color: #f5f5f5;
      position: absolute;
      bottom: 0;
      left: 0;
      height: 8px;
      border-radius: 2px;
      .summary-progress-bar {
        height: 100%;
        background-color: #007c16;
      }
    }
  }
}

.schedule-edit-close {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .schedule-btn {
    border: 2px solid #6c757d;
    background-color: #6c757d;
    color: #fff;
    outline: none;
    width: 110px;
    height: 45px;
    padding: 8px;
    border-radius: 2px;
    margin-left: 15px;
  }
}

.update-widgets-modal.media.extra-width.w-90 {
  width: 95% !important;
}

@media (max-width: 768px) {
  .update-widgets-modal.media.extra-width.w-90 {
    width: 100% !important;
  }
}

.echeck-notes-div {
  padding: 15px 10px 10px 10px;

  h5 {
    color: #6a6c6f;
    font-size: 18px;
    font-weight: 600;
  }

  div {
    background-color: #fafafa;
    padding: 10px;
    max-height: 250px;
    overflow-y: auto;
  }
  // webkit browsers
  div::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  div::-webkit-scrollbar-thumb {
    background-color: #e1e1e9;
    border-radius: 3px;
  }

  div::-webkit-scrollbar-thumb:hover {
    background-color: #b1b1b9;
  }

  div::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  div::-webkit-scrollbar-track:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.banking-details-instruction {
  color: #6a6c6f;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 4px;
  line-height: 1.4;
}

.associate-email-btn {
  background: none;
  border: none;
  color: #007c16;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  margin-top: 8px;
  text-align: left;
  transition: color 0.2s ease-in-out;
}

.associate-email-btn:hover {
  text-decoration: underline;
  color: #005a12;
}

.associate-email-btn:focus {
  outline: 2px solid #007c16;
  outline-offset: 2px;
}
