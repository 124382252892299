.sidebar {
  min-width: 220px;
  max-width: 220px;
  background-color: #f7f9fa;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.3s ease;
}

.sidebar.hidden {
  margin-left: -220px;
}

.sidebar-links-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
}

.sidebar-links-container::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.sidebar-links-container::-webkit-scrollbar-thumb {
  background-color: #e1e1e9;
  border-radius: 3px;
}

.sidebar-links-container::-webkit-scrollbar-thumb:hover {
  background-color: #b1b1b9;
}

.sidebar-links-container::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.sidebar-links-container::-webkit-scrollbar-track:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.sidebar-links-container .sidebar-link {
  width: 100%;
  padding: 15px 20px;
  border-bottom: 1px solid #eaeaea;
  cursor: pointer;
  color: #6a6c6f;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.09em;
  font-size: 13px;
  text-decoration: none;
  position: relative;
}

.sidebar-links-container:first-child {
  border-top: 1px solid #eaeaea;
}

.sidebar-link:hover,
.sidebar-link.active,
.sidebar-link:focus {
  background-color: #ffffff;
  text-decoration: none;
  color: #6a6c6f;
}

.sidebar-link.white {
  background-color: #fff;
}

.sidebar-link.transparent {
  background-color: transparent;
}

.arrow-icon {
  position: absolute;
  top: 15px;
  right: 10px;
}

.dropdown-content {
  background-color: #fff;
  max-height: 0;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease;
}

.dropdown-content.open {
  max-height: 500px;
  visibility: visible;
  opacity: 1;
}

.dropdown-content:first-child {
  border-top: 1px solid #eaeaea;
}

.dropdown-content .dropdown-link {
  width: 100%;
  display: block;
  border-bottom: 1px solid #eaeaea;
  padding: 10px 10px 10px 30px;
  color: #6a6c6f;
  text-transform: none;
  font-weight: 600;
  letter-spacing: 0.09em;
  font-size: 12px;
  white-space: nowrap;
}

.dropdown-content .dropdown-link:hover,
.dropdown-content .dropdown-link.active,
.dropdown-content .dropdown-link:focus {
  background-color: rgba(228, 228, 228, 0.6);
  text-decoration: none;
  color: #6a6c6f;
}

.logout-div {
  background-color: #fff;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
}

.user-circle {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #007c16;
  color: #fff;
  font-weight: 600;
  letter-spacing: 0.2em;
  font-size: 16px;
  cursor: pointer;
}

.bg-red {
  background-color: #dc3545 !important;
}

.bg-green {
  background-color: #007c16 !important;
}

.custom-notification-badge{
  padding: 0.55em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  border-radius: 0.27rem;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  position: absolute;
  top: 10px;
  right: 15px;
  width: 40px;
  height: 20px;
}
