.media-heading-button-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .media-heading-button-container {
    flex-direction: column;
    justify-content: center;
  }
}

.widgets-row,
.media-row {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 25px;
}

.media-row.show-filter-loading {
  min-height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.widgets-box,
.media-box {
  width: 100%;
  height: fit-content;
  margin-bottom: 10px;
  border: 1px solid #eaeaea;
  border-radius: 2px;
  overflow: hidden;
}

@media (min-width: 768px) {
  .widgets-box {
    width: calc(50% - 10px);
    margin-right: 10px;
  }
}

@media (min-width: 992px) {
  .widgets-box {
    width: calc(25% - 15px);
    margin-right: 15px;
  }
}

@media (min-width: 1200px) {
  .media-box {
    width: calc(50% - 5px) !important;
    margin-right: 5px !important;
  }
}

@media (min-width: 1281px) {
  .media-box.donors-box {
    width: calc(33.33% - 10px); /* Adjusted width to fit three components */
    margin-right: 10px; /* Adjusted margin */
    min-height: 420px;
  }

  /* To remove the right margin from the last component in each row */
  .media-box.donors-box:nth-child(3n) {
    margin-right: 0;
  }
}

.widgets-box .background {
  width: 100%;
  height: 150px;
  background-color: #007c16;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  position: relative;
}

.background .schedule-icon {
  position: absolute;
  top: 3px;
  right: 5px;
  color: #fff;
  cursor: pointer;
}

.widget-value-div {
  color: #007c16;
}

.background h4 {
  margin-top: 15px;
  text-align: center;
}

.widgets-box .status-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #fff;
}

.status-box span {
  font-weight: 500;
  font-size: 14px;
  color: #6a6c6f;
}

.widgets-box .edit-box {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
  background-color: #fff;
}

.edit-box button {
  border: hidden;
  outline: none;
  border-radius: 3px;
  width: 50px;
  padding: 5px;
}

/* media */
.media-box {
  padding: 5px;
}

.media-box .media-flex-between-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.media-name {
  font-weight: 600;
  font-size: 32px;
  color: #6a6c6f;
}

.media-flex-between-row .mediatype-and-status-div {
  display: flex;
  align-items: center;
}

.mediatype-and-status-div .media-type,
.mediatype-and-status-div .media-muted {
  color: #6a6c6f;
  border: 1px solid #eaeaea;
  padding: 5px 10px;
  margin-right: 10px;
  cursor: pointer;
}

.media-flex-between-row .media-description {
  color: #6a6c6f;
  font-weight: 500;
  font-size: 18px;
}

.media-flex-between-row .media-img-div {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  border: 1px solid #eaeaea;
  padding: 5px;
  cursor: pointer;
}

.media-img-div.video {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #6c757d;
  color: #fff;
  border: 1px solid #fff;
}

.media-img-div img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: contain;
}

.media-thead {
  background-color: #fbfbfb;
}

.media-thead tr th {
  font-weight: 600;
  font-size: 14px;
  white-space: nowrap;
  word-break: keep-all;
}

.media-body tr td {
  white-space: nowrap;
  word-break: keep-all;
}

/* scroll table */
.media-table-container {
  width: 100%;
  overflow: auto;
}

.media-table-container::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.media-table-container::-webkit-scrollbar-thumb {
  background-color: #e1e1e9;
  border-radius: 3px;
}

.media-table-container::-webkit-scrollbar-thumb:hover {
  background-color: #b1b1b9;
}

.media-table-container::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.media-table-container::-webkit-scrollbar-track:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.edit-and-delete-media-div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
  position: sticky;
}

.edit-and-delete-media-div.justify-between {
  justify-content: space-between;
}

@media (max-width: 1280px) {
  .edit-and-delete-media-div.justify-between {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .edit-and-delete-media-div.justify-between div {
    width: 100%;
  }

  .edit-and-delete-media-div.justify-between div button {
    width: 100% !important;
    margin: 5px 0 !important;
  }
}

.edit-and-delete-media-div button {
  width: 110px;
  padding: 8px;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-left: 5px;
}

.edit-and-delete-media-div .edit-btn {
  background-color: #007c16;
  color: #fff;
}

.edit-and-delete-media-div .delete-btn {
  background-color: #dc3545;
  color: #fff;
}

.edit-and-delete-media-div .grey-btn {
  background-color: #6a6c6f;
  color: #fff;
}

/* per-page-and-pagination */
.per-page-and-pagination {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 35px;
  margin-right: 10px;
  padding: 20px 0 8px 0;
}

.per-page {
  display: flex;
  align-items: center;
  margin-bottom: -2px;
  margin-right: 25px;
}

@media (max-width: 450px) {
  .per-page-and-pagination {
    flex-direction: column;
    justify-content: center;
  }

  .per-page {
    margin-bottom: 10px;
  }
}

.per-page label {
  margin-right: 10px;
}

.page-link {
  background-color: #fff;
  color: #007c16 !important;
  margin: 0 7px 0 7px;
  border: 1px solid #eaeaea;
}

.active .page-link {
  background-color: #007c16 !important;
  color: #fff !important;
  z-index: 1 !important;
}

/* update widget modal css */
.update-widgets-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100dvh;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100001;
}

.update-widgets-backdrop .update-widgets-modal {
  width: 375px;
  height: fit-content;
  max-height: calc(100dvh - 50px);
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

.update-widgets-modal.w-500 {
  width: 500px;
}

@media (max-width: 540px) {
  .update-widgets-modal.w-500 {
    width: 100%;
    min-height: 100dvh;
    max-height: 100dvh;
  }
}

.update-widgets-modal.media {
  width: 750px;
}

.update-widgets-modal.news-popup {
  width: 90%;
  min-height: calc(100dvh - 50px);
}

@media (max-width: 768px) {
  .update-widgets-modal.media {
    width: 100%;
    min-height: 100dvh;
    max-height: 100dvh;
  }
}

@media (max-width: 1024px) {
  .update-widgets-modal.news-popup {
    width: 100%;
    min-height: 100dvh;
    max-height: 100dvh;
  }
}

.update-widgets-backdrop .update-widgets-modal.extra-width {
  width: 900px;
}

@media (max-width: 912px) {
  .update-widgets-backdrop .update-widgets-modal.extra-width {
    width: 100%;
    min-height: 100dvh;
    max-height: 100dvh;
  }
}

.update-widgets-modal.drawer {
  position: absolute;
  top: 0;
  right: 0;
  min-height: 100dvh;
  background-color: #fff;
  width: 500px;
}

@media (max-width: 540px) {
  .update-widgets-modal.drawer {
    width: 100%;
  }
}

@media (max-width: 450px) {
  .update-widgets-backdrop .update-widgets-modal {
    width: 100%;
    min-height: 100dvh;
    max-height: 100dvh;
  }
}

.update-widgets-modal .update-widgets-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #007c16;
  color: #fff;
}

.update-widgets-modal-header div {
  font-size: 14px;
}

.update-widgets-modal-header .cancel-widget-update-icon {
  cursor: pointer;
}

.update-widgets-modal .update-widgets-modal-body {
  padding: 20px;
  overflow-y: auto;
  flex-grow: 1;
  position: relative;
}

/* Customizing the scrollbar */
.update-widgets-modal-body::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.update-widgets-modal-body::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.update-widgets-modal-body::-webkit-scrollbar-thumb {
  background-color: #e1e1e9;
  border-radius: 3px;
}

.update-widgets-modal-body::-webkit-scrollbar-thumb:hover {
  background-color: #b1b1b9;
}

.update-widgets-modal-body::-webkit-scrollbar-track:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.custom-textarea {
  width: 100%;
  height: 230px;
  padding: 10px;
  margin-bottom: 5px;
  border: 1px solid #e4e4e4;
  border-radius: 2px;
}

/* Customizing the scrollbar */
.custom-textarea::-webkit-scrollbar {
  width: 6px;
}

.custom-textarea::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.custom-textarea::-webkit-scrollbar-thumb {
  background-color: #e1e1e9;
  border-radius: 3px;
}

.custom-textarea::-webkit-scrollbar-thumb:hover {
  background-color: #b1b1b9;
}

.custom-textarea::-webkit-scrollbar-track:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.custom-textarea:focus,
.custom-textarea:active {
  outline-color: #007c16;
  border-color: #007c16;
}

/* switch */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-bottom: 10px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

.switch input:checked + .slider {
  background-color: #007c16;
}

.switch input:focus + .slider {
  box-shadow: 0 0 1px #007c16;
}

.switch input:checked + .slider:before {
  transform: translateX(26px);
}

.update-btn-div {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.update-btn-div button {
  outline: none;
  border-radius: 2px;
  padding: 8px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.update-btn-div .cancel-widget-update {
  border: 2px solid #6c757d;
  background-color: #6c757d;
  color: #fff;
  margin-right: 15px;
}

.update-btn-div .update-widget {
  border: 2px solid #007c16;
  background-color: #007c16;
  color: #fff;
}

/* media form */
.update-media-form label {
  margin-bottom: 3px;
}

.edit-media-inputs {
  min-height: auto;
  padding-top: 0.33em;
  padding-bottom: 0.33em;
  padding-left: 0.75em;
  padding-right: 0.75em;
  border: 1px solid #bdbdbd;
  background: transparent;
  transition: all 0.2s linear;
  display: block;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  color: #4f4f4f;
  appearance: none;
  border-radius: 0.25rem;
}

.input-margins-div {
  margin: 5px 0 5px 0;
}

.edit-media-inputs:focus,
.edit-media-inputs:active {
  border-color: #007c16;
  outline-color: #007c16;
}

.edit-media-inputs.text-area {
  resize: none;
  height: auto;
  max-height: 5em;
}

/* Customizing the scrollbar */
.edit-media-inputs.text-area::-webkit-scrollbar {
  width: 6px;
}

.edit-media-inputs.text-area::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.edit-media-inputs.text-area::-webkit-scrollbar-thumb {
  background-color: #e1e1e9;
  border-radius: 3px;
}

.edit-media-inputs.text-area::-webkit-scrollbar-thumb:hover {
  background-color: #b1b1b9;
}

.edit-media-inputs.text-area::-webkit-scrollbar-track:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.submit-and-cancel-div {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 15px 0;
  border-top: 1px solid #eaeaea;
}

.submit-and-cancel-div .cancel {
  width: 110px;
  padding: 8px;
  border-radius: 2px;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  border: 2px solid #6c757d;
  background-color: #6c757d;
  color: #fff;
}

.submit-and-cancel-div .submit {
  width: 110px;
  padding: 8px;
  border-radius: 2px;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid;
  color: #fff;
}

.submit.addModal {
  border-color: #007c16;
  background-color: #007c16;
}

.submit.delete {
  border-color: #dc3545;
  background-color: #dc3545;
}

.submit.update {
  border-color: #007c16;
  background-color: #007c16;
}

.delete-consequences-container p {
  font-size: 14px;
  font-weight: 500;
}

.reset-and-filter-div {
  display: flex;
  margin-top: 15px;
}

.reset-and-filter-div button {
  flex: 1;
  padding: 8px;
  border: 2px solid;
  border-radius: 2px;
  outline: none;
  color: #fff;
}

.reset-and-filter-div .reset-fields-btn {
  background-color: #6a6c6f;
  border-color: #6a6c6f;
}

.reset-and-filter-div .filter-records-btn {
  border-color: #007c16;
  background-color: #007c16;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-btn {
  width: 110px;
  padding: 4px;
  border-radius: 2px;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid;
  color: #fff;
}

.custom-btn.green {
  border-color: #007c16;
  background-color: #007c16;
}

.custom-btn.grey {
  border: 2px solid #6c757d;
  background-color: #6c757d;
}
