.dropup .pledge-dropdown-toggle::after {
  display: inline-block !important;
  margin-left: 0.955em !important;
  vertical-align: 0.255em !important;
  content: "" !important;
  border-top: 0 !important;
  border-right: 0.3em solid transparent !important;
  border-bottom: 0.3em solid !important;
  border-left: 0.3em solid transparent !important;
}

.graph-container-in-pledge {
  width: 100%;
  flex: 1;
  min-height: 250px;
  max-height: 300px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.graph-container-in-pledge:before {
  content: "";
  display: block;
  padding-top: 60%;
}

.graph-container-in-pledge canvas {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}
