.import-radio-container {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  margin: 15px 0;
  .radio-button {
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    cursor: pointer;
    border: 2px solid #eaeaea;
    border-radius: 2px;
    @media (max-width: 450px) {
      width: 100%;
    }
  }
  .radio-button.selected {
    border: 2px solid #007c16;
  }
  @media (max-width: 450px) {
    flex-direction: column;
    justify-content: center;
  }
}
