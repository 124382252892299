.apps-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 25px;

  .app-box {
    width: 100%;
    height: fit-content;
    min-height: 600px;
    margin-bottom: 20px;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    @media (min-width: 840px) {
      width: calc(50% - 15px);
      margin-right: 15px;
    }

    @media (min-width: 1400px) {
      width: calc(33.33% - 15px);
    }

    .app-card-header {
      padding: 5% 5% 0 5%;
      border-radius: 6px 6px 0px 0px;
      position: relative;

      img {
        max-width: 100%;
        height: auto;
      }

      .tool-icon-2 {
        position: absolute;
        width: 8%;
        top: 22%;
        bottom: auto;
        right: 13.5%;
        left: auto;
      }

      .tool-icon-2.connected{
        top: 19%;
        right: 17.75%;
      }
    }

    .app-card-body {
      padding: 10px 25px;
      color: #333;

      h3 {
        margin-top: 15px;
        font-size: 28px;
        font-weight: bold;
      }

      p {
        margin-top: 25px;
        font-size: 22px;
        font-weight: 500;
        line-height: 34px;
      }

      p:hover {
        color: #007c16;
      }
    }

    .app-card-footer {
      width: 100%;
      border-radius: 0px 0px 6px 6px;
      border: 1px solid #e4e4e4;
      padding: 8px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .state-img,
      .status-img {
        width: 35px;
        height: 35px;
        object-fit: contain;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .status-img {
        margin-left: 6px;
        margin-right: 8px;
      }
    }
  }
}
