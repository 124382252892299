.card-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.card {
  flex: 0 0 calc(25% - 40px);
  height: fit-content;
  margin: 20px;
  background-color: #fff;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

@media (max-width: 1280px) {
  .card {
    flex: 0 0 calc(50% - 40px);
  }
}

@media (max-width: 768px) {
  .card {
    flex: 0 0 100%;
    margin: 0;
    margin-bottom: 20px;
  }
}

.card-title {
  padding: 10px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.09em;
  border-bottom: 1px solid #eaeaea;
}

.card-title.green {
  background-color: #007c16;
  color: #fff;
}

.card-title.yellow {
  background-color: rgb(242, 185, 44);
  color: #000;
}

.card-content {
  flex: 1;
  background-color: #fff;
  padding: 10px;
}

.data-card-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px 0;
}

.data-card-item .label {
  flex: 1;
  font-weight: bold;
}

.search-year-form {
  display: flex;
  align-items: center;
  gap: 2px;
  margin-right: 15px;
}

.search-year-form input {
  width: 150px;
  padding: 8px 12px;
  font-size: 16px;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.search-year-form input:focus {
  border-color: #007c16;
  box-shadow: 0 0 4px rgba(0, 124, 22, 0.5);
}

.search-year-form button {
  padding: 8px 12px;
  background-color: #007c16;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s, transform 0.2s;
}

.search-year-form button:active {
  transform: scale(0.95);
}
