.crm-users-info {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px;
  overflow: hidden;
}

.crm-users-info.flex-start {
  align-items: flex-start;
}

.user-name-email {
  margin-left: 15px;
}

.user-name-email h4 {
  color: #6a6c6f;
  font-weight: 500;
  font-size: 16px;
}

@media (max-width: 375px) {
  .crm-users-info.flex-start {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .user-name-email {
    margin-left: 0;
    margin-top: 15px;
  }
}

.donors-table-container {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.donors-table-container .donors-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  background-color: #fbfbfb;
}

.donors-row h4 {
  font-size: 16px;
  font-weight: 600;
}
