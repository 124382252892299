.analytics-row {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.analytics-row-box {
  flex: 1;
  height: 380px;
  background: #fff;
  border: 1px solid #eaeaea;
  border-radius: 2px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 15px;
}

.analytics-row-box.right-most {
  margin-right: 0;
}

@media (max-width: 767px) {
  .analytics-row {
    flex-direction: column;
  }

  .analytics-row-box {
    width: 100%;
    height: auto;
    margin-right: 0;
    margin-bottom: 15px;
  }

  .analytics-row-box.right-most {
    margin-bottom: 0;
  }
}

.analytics-row-box .row-content {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  position: relative;
}

.row-content::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.row-content::-webkit-scrollbar-thumb {
  background-color: #e1e1e9;
  border-radius: 3px;
}

.row-content::-webkit-scrollbar-thumb:hover {
  background-color: #b1b1b9;
}

.row-content::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.row-content::-webkit-scrollbar-track:hover {
  background-color: rgba(
    0,
    0,
    0,
    0.1
  );
}

.absoluted-box {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.progress-container {
  margin-bottom: 5px;
}

.progress-and-percentage {
  width: 98%;
  display: flex;
  align-items: center;
}

.channel-progress-bar {
  width: 100%;
  height: 24px;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background-color: #f5f5f5;
  display: flex;
}

.channel-progress-bar .progress-bar-filled {
  border-radius: 2px;
}

.percentage-div {
  width: 85px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tenders-data-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.tenders-data-row .tenders-data-box {
  width: 47%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: #fff;
  border-right: 1px solid #eaeaea;
  border-left: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
  margin: 5px 5px 5px 0;
  padding: 5px 10px 5px 10px;
  border-radius: 2px;
}

@media (max-width: 1200px) {
  .tenders-data-row {
    flex-direction: column;
  }

  .tenders-data-row .tenders-data-box {
    width: 99%;
    margin: 0 0 5px 0;
  }
}

.tenders-data-row .tenders-data-box h4 {
  color: #007c16;
  font-size: 28px;
  letter-spacing: 0.09em;
}

.top-donations-data-row{
  display: flex;
  flex-direction: column;
}

.top-donations-data-row .top-donations-data-box{
  width: 99%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: #fff;
  border-right: 1px solid #eaeaea;
  border-left: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
  margin: 0 0 5px 0;
  padding: 5px 10px 5px 10px;
  border-radius: 2px;
}

.top-donations-data-row h4 {
  color: #007c16;
  font-size: 28px;
  letter-spacing: 0.09em;
}

@media(max-width: 1280px){
  .top-donations-data-row{
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .top-donations-data-row .top-donations-data-box{
    width: 47%;
    margin: 5px 5px 5px 0;
  }
}

@media(max-width: 540px){
  .top-donations-data-row{
    flex-direction: column;
  }

  .top-donations-data-row .top-donations-data-box{
    width: 99%;
    margin: 0 0 5px 0;
  }
}

.dashboard-row-left.full-width.flex {
  display: flex;
  flex-direction: column;
  height: 390px;
}

@media (max-width: 1280px) {
  .dashboard-row-left.full-width.flex {
    height: auto;
    min-height: 250px;
    max-height: fit-content;
  }
}

.pyramid-graph-container {
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.pyramid-container {
  width: 50%;
  height: 100%;
  max-height: 300px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pyramid-container:before {
  content: "";
  display: block;
  padding-top: 60%;
}

.pyramid-container canvas {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.graph-info {
  width: 50%;
  height: 100%;
  max-height: 300px;
  overflow: auto;
}

.graph-info::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.graph-info::-webkit-scrollbar-thumb {
  background-color: #e1e1e9;
  border-radius: 3px;
}

.graph-info::-webkit-scrollbar-thumb:hover {
  background-color: #b1b1b9;
}

.graph-info::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.graph-info::-webkit-scrollbar-track:hover {
  background-color: rgba(
    0,
    0,
    0,
    0.1
  );
}

@media (max-width: 1280px) {
  .pyramid-graph-container {
    flex-direction: column;
  }

  .pyramid-container {
    width: 100%;
  }

  .graph-info {
    width: 100%;
  }
}

.textbox-values-div {
  margin: 5px 0 5px 0;
}

.textfields-div {
  display: flex;
  justify-content: space-between;
}

.custom-field {
  margin-right: 10px !important;
}

.custom-field:last-child{
  margin-right: 0 !important;
}

.custom-badge {
  border-radius: 0.27rem;
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
}
