.slideshow-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  @media (max-width: 1024px) {
    height: auto;
  }

  .slideshow-container {
    width: 100%;
    flex-grow: 1;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 1024px) {
      height: auto;
      flex-direction: column;
    }

    .carousel-content {
      flex: 0.8;
      height: 100%;
      overflow: hidden;
      padding: 0 50px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-color: #fff;
      @media (max-width: 1024px) {
        width: 100%;
        min-height: 550px;
        padding: 0 20px;
      }

      .main-content {
        margin-top: 35px;
        .name {
          color: #333333;
          padding-bottom: 10px;
          border-bottom: 1px solid #333333;
          margin-bottom: 25px;
          font-size: 28px;
          width: 100%;
        }
        .description {
          font-size: 18px;
          font-weight: 500;
          width: 100%;
          color: #555;
          height: 250px;
          overflow: auto;
          // box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
          padding: 10px;
          text-align: justify;

          @media (max-width: 1024px) {
            height: auto;
            max-height: 350px;
          }
        }

        .description::-webkit-scrollbar {
          width: 8px; /* Width of the scrollbar */
        }

        .description::-webkit-scrollbar-track {
          background-color: #f1f1f1; /* Color of the scrollbar track */
        }

        .description::-webkit-scrollbar-thumb {
          background-color: #e1e1e9; /* Color of the scrollbar thumb */
          border-radius: 4px; /* Rounded corners of the scrollbar thumb */
        }

        .description::-webkit-scrollbar-thumb:hover {
          background-color: #b1b1b9; /* Color of the scrollbar thumb on hover */
        }

        .progress-container {
          margin-top: 50px;
          width: 100%;
          height: 20px;
          background-color: #e0e0e0;
          border-radius: 10px;
          overflow: hidden;

          .progress-bar {
            height: 100%;
            background-color: #007c16;
            transition: width 1s linear;
          }
        }
      }

      .secondary-content {
        margin-bottom: 35px;
        @media (max-width: 1024px) {
          margin-top: 50px;
        }
        .back-and-next-container {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #555;
          .back,
          .next {
            cursor: pointer;
            background-color: #007c16;
            color: #fff;
            padding: 10px;
          }
          .indicator {
            font-size: 24px;
            user-select: none;
            letter-spacing: 0.09em;
          }
        }
      }
    }

    .carousel-images {
      flex: 1.2;
      height: 100%;
      overflow: hidden;
      display: flex;
      align-items: center;
      background-color: #fff;
      padding: 10px;
      @media (max-width: 1024px) {
        width: 100%;
      }

      .current-img-div {
        position: relative;
        flex: 1;
        padding: 0;
        height: 100%;
        overflow: hidden;
        cursor: pointer;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        flex-wrap: nowrap;
        @media (max-width: 1024px) {
          min-height: 250px;
        }

        .current-image {
          box-sizing: border-box;
          min-width: 100%;
          height: 100%;
          overflow: hidden;
          object-fit: cover;
          transform: translateX(0);
          transition: transform 1s ease;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
            // border: 2px solid #007c16;
            border-radius: 3px;
            @media (max-width: 450px) {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .video-container {
            border-radius: 46px;
            background: #747372;
            width: 100%;
            height: 100%;
            margin: 33px;
            overflow: hidden;
            position: relative;
            // border: 4px solid #007c16;
            @media (max-width: 1024px) {
              height: auto;
              aspect-ratio: 330/260;
              border-radius: 23px;
              margin: 13px;
            }
          }
        }
      }
    }
  }
}
