$GREENCOLOR: #007c16;

.reportsComponent {
  .searchReports {
    max-width: 600px;
  }

  .paragraph-box {
    margin-top: 20px;
    padding: 8px 16px;
    background-color: #fff;
    .textJustify {
      text-align: justify;
      margin-left: 5px;
      padding-bottom: 10px;
      border-bottom: 1px solid #eaeaea;
    }
  }

  .controls-box {
    padding: 8px 16px;
    background-color: #fff;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .controls-form {
      width: 100%;
      .controls-div {
        width: 100%;
        margin-bottom: 15px;
        .date-picker-container{
          margin-top: -5px;
        }
      }
      .submit {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 20px 0;
        .btnGreen {
          width: 180px;
          background-color: $GREENCOLOR;
          padding: 10px 14px;
          color: #fff;
          border: none;
          outline: none;
          font-size: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}
