:root {
  --login-page-background-color: #f8f9fb;
  --auth-green-text-color: #007c16;
}

.login-background {
  width: 100%;
  min-height: 100vh;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--login-page-background-color);
}

.login-background .login-box {
  width: 700px;
  display: flex;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.login-box .leftside {
  flex: 0.8;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.leftside img {
  width: 100%;
  height: 100%;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.login-box .rightside {
  flex: 1.2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
}

.rightside .mervice-logo {
  width: 175px;
  height: auto;
}

.mervice-logo img {
  width: 100%;
  height: 100%;
}

.rightside h4 {
  margin: 20px 0;
  letter-spacing: 0.09em;
  text-align: center;
}

.rightside h4 .green {
  color: var(--auth-green-text-color);
}

.rightside .custom-form {
  width: 100%;
}

.custom-form .login-submit-btn {
  width: 100%;
  padding: 10px 14px;
  background-color: var(--auth-green-text-color);
  outline: none;
  border: none;
  letter-spacing: 0.09em;
  color: #fff;
  cursor: pointer;
  margin-top: 20px;
  font-size: 15px;
  font-weight: 600;
}

.custom-form .forgot-password-div {
  font-weight: 600;
  letter-spacing: 0.09em;
  width: 100%;
  text-align: center;
  margin-top: 20px;
  font-size: 13px;
}

.forgot-password-div .forgot-password-link {
  text-decoration: none;
  color: #a7a7a7;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #fff inset !important;
}

.error-msg {
  width: 100%;
  background-color: #f8d7da;
  font-size: 12px;
  color: #721c24;
  border-color: #f5c6cb;
  padding: 0.75rem 1.25rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.success-msg {
  width: 100%;
  background-color: #d4edda;
  font-size: 12px;
  color: #155724;
  border-color: #c3e6cb;
  padding: 0.75rem 1.25rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.info-msg {
  width: 100%;
  font-size: 12px;
  background-color: #f0f0f0;
  color: #333333;
  border-color: #c0c0c0;
  padding: 0.75rem 1.25rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

@media (max-width: 768px) {
  .login-background .login-box {
    width: 70%;
  }

  .login-box .leftside {
    display: none;
  }

  .login-box .rightside {
    flex: 1;
  }
}

@media (max-width: 540px) {
  .login-background {
    background-color: #ffffff;
    background-image: none;
    align-items: flex-start;
  }

  .login-background .login-box {
    width: 100%;
    box-shadow: none;
  }
}

.store-container {
  max-width: 700px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 50px;
}

.store-container .store {
  width: 150px;
  max-height: 100px;
}

.store-container .store img {
  width: 100%;
  height: 100%;
}

.tippy-box {
  padding-top: 3.5px;
  padding-bottom: 3.5px;
}
