.recurring-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  @media (max-width: 540px) {
    flex-direction: column;
    justify-content: center;
  }
  .recurring-leftside {
    display: flex;
    align-items: center;
    @media (max-width: 540px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .recurring-campaign-image {
      width: 80px;
      height: auto;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .recurring-campaign-details {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
      @media (max-width: 540px) {
        align-items: center;
        margin-left: 0;
      }
      h6 {
        color: #6a6a6a;
        font-size: 14px;
        margin-bottom: -10px;
        @media (max-width: 540px) {
          margin-bottom: 0;
        }
      }
      h1 {
        color: #000;
        font-size: 32px;
        margin: 0;
        @media (max-width: 540px) {
          margin-bottom: 10px;
        }
      }
      h5 {
        font-size: 18px;
        margin-top: 5px;
        @media (max-width: 540px) {
          margin-top: 0;
        }
        .donor-hyper-link {
          color: #4f4f4f;
          font-weight: 400 !important;
          margin-bottom: 0.25rem !important;
        }
        .donor-hyper-link:hover {
          color: #007c16;
        }
      }
    }
  }
  .recurring-rightside {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
    @media (max-width: 540px) {
      align-items: center;
    }
    h6 {
      color: #6a6a6a;
      font-size: 14px;
      margin-bottom: 0px;
    }
    h1 {
      color: #000;
      font-size: 32px;
      margin: 0;
    }
  }
}

.recurring-table th,
.recurring-table td {
  text-align: center;
  vertical-align: middle;
  padding: 10px;
  width: 25%; /* Set a fixed width for each column */
}

.recurring-summary-row {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 25px;
  .recurring-summary-box {
    width: 100%;
    height: fit-content;
    margin-bottom: 20px;
    padding: 20px;
    background-color: #fff;
    border-radius: 2px;
    border: 1px solid #eaeaea;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    @media (min-width: 540px) {
      width: calc(50% - 10px);
      margin-right: 10px;
    }

    @media (min-width: 768px) {
      width: calc(33.33% - 5px);
      margin-right: 5px;
    }

    h4 {
      color: #6a6c6f;
      font-size: 18px;
      font-weight: 600;
      span {
        margin-left: 5px;
        cursor: pointer;
      }
    }
    p {
      color: #007c16;
      font-weight: 600;
      margin-top: 20px;
      font-size: 20px;
    }
    img {
      width: 60px;
      height: 60px;
      position: absolute;
      top: 10px;
      right: 15px;
    }
    .summary-progress-bar-div {
      width: 100%;
      background-color: #f5f5f5;
      position: absolute;
      bottom: 0;
      left: 0;
      height: 8px;
      border-radius: 2px;
      .summary-progress-bar {
        height: 100%;
        background-color: #007c16;
      }
    }
  }
}

.recurring-notes-div {
  padding: 0;
  div {
    background-color: #fafafa;
    padding: 10px;
    min-height: 150px;
    max-height: 250px;
    overflow-y: auto;
  }
  // webkit browsers
  div::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  div::-webkit-scrollbar-thumb {
    background-color: #e1e1e9;
    border-radius: 3px;
  }

  div::-webkit-scrollbar-thumb:hover {
    background-color: #b1b1b9;
  }

  div::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  div::-webkit-scrollbar-track:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.react-date-picker__inputGroup__input--hasLeadingZero {
  margin-left: -0.25em;
  padding-left: calc(1px + 0.54em);
}

.react-date-picker__inputGroup {
  min-width: calc((4px * 3) + 0.54em * 8 + 0.217em * 2);
  flex-grow: 1;
  padding: 0 10px;
  box-sizing: content-box;
}
