header {
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  border-bottom: 1px solid #eaeaea;
}

header .header-leftside {
  display: flex;
  align-items: center;
}

.header-leftside .header-logo {
  width: 220px;
  height: 60px;
  background-color: #f7f9fa;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.header-logo img {
  width: auto;
  height: 100%;
  max-width: none;
  object-fit: contain;
}

.header-leftside .header-menu-icon {
  padding: 18px 26px 17px 26px;
  border-left: 1px solid #eaeaea;
  border-right: 1px solid #eaeaea;
  margin-right: 15px;
  cursor: pointer;
  color: #6a6c6f;
  background-color: #f7f9fa;
}

.header-leftside .header-menu-icon:hover {
  background-color: #f0f7f9;
}

/* not searchable dropdown */
.organizations-dropdown-button {
  background-color: #007c16 !important;
  box-shadow: none !important;
}

.dropdown-toggle::after {
  margin-left: 0.955em !important;
}

.dropdown-menu {
  z-index: 10002 !important;
  max-height: 300px !important;
  overflow-y: auto !important;
}

.dropdown-menu::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.dropdown-menu::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Color of the scrollbar track */
}

.dropdown-menu::-webkit-scrollbar-thumb {
  background-color: #e1e1e9; /* Color of the scrollbar thumb */
  border-radius: 4px; /* Rounded corners of the scrollbar thumb */
}

.dropdown-menu::-webkit-scrollbar-thumb:hover {
  background-color: #b1b1b9; /* Color of the scrollbar thumb on hover */
}

/* searchable dropdown */
.dropdown {
  position: relative;
}

.dropdown .arrow {
  border-color: #fff transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: " ";
  display: block;
  height: 0;
  margin-top: 0.3rem;
  position: absolute;
  right: 10px;
  top: 14px;
  width: 0;
}

.dropdown .arrow.open {
  border-color: transparent transparent #fff;
  border-width: 0 5px 5px;
}

.dropdown .selected-value input {
  line-height: 1.5;
  background-color: #007c16;
  color: #fff;
  border: 0;
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 0.5rem;
  box-sizing: border-box;
  cursor: default;
  outline: none;
  padding: 0.625rem 1.5rem 0.5rem;
  transition: all 200ms ease;
  min-width: 200px;
  max-width: 400px;
}

.hidden-span {
  visibility: hidden;
  white-space: nowrap;
  position: absolute;
  font-size: 0.75rem;
  font-weight: 500;
  padding: 0.625rem 1.5rem 0.5rem;
}

.dropdown .options {
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-top: -1px;
  position: absolute;
  top: 100%;
  width: 100%;
  height: 0;
  -webkit-overflow-scrolling: touch;
  display: block;
  visibility: hidden;
  opacity: 0;
  transition: all 200ms ease-in-out;
}

.dropdown .options.open {
  height: auto;
  visibility: visible;
  opacity: 1;
}

.dropdown .option {
  box-sizing: border-box;
  color: rgba(51, 51, 51, 0.8);
  cursor: pointer;
  display: block;
  padding: 8px 10px;
}

.dropdown .option.selected,
.dropdown .option:hover {
  background-color: rgba(0, 124, 22, 0.1);
  color: #333;
}

/* header rightside starts */
.header-rightside {
  position: relative;
  white-space: nowrap;
}

@media (max-width: 876px) {
  .header-rightside {
    display: none;
  }
}

.rightside-links {
  margin-left: 10px;
  padding: 18px 26px 17px 26px;
  cursor: pointer;
  color: #9d9fa2;
}

.rightside-links:hover {
  background-color: #f0f7f9;
}

.header-dropdown {
  padding: 20px;
  font-size: 13px;
  border-radius: 3px;
  position: absolute;
  top: 60px;
  z-index: 10003;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  visibility: hidden;
  opacity: 0;
}

.header-dropdown.notifications-links {
  right: 253px;
  flex-direction: column;
  padding: 0;
}

.header-dropdown.quick-links {
  right: 36px;
}

.header-dropdown.email-links {
  right: 83px;
}

.header-dropdown.open {
  visibility: visible;
  opacity: 1;
  animation-name: flipInX;
  animation-duration: 1s;
  animation-fill-mode: both;
}

@keyframes flipInX {
  0% {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transition-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transition-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  100% {
    transform: perspective(400px);
  }
}

.dropdown-box {
  width: 120px;
  color: #9d9fa2;
  cursor: pointer;
  margin: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dropdown-box .dropdown-box-links {
  color: #9d9fa2;
}

.dropdown-box .dropdown-box-links:hover {
  color: #9d9fa2;
}

.dropdown-box .dropdown-box-links.active {
  color: #007c16;
}

.dropdown-box h5 {
  font-size: 13px;
  margin-top: 15px;
  font-weight: 500;
  line-height: 1.1;
  text-align: center;
}

.dropdown-box .blue {
  color: #3498db;
}

.dropdown-box .yellow {
  color: #ffb606;
}

.dropdown-box .green {
  color: #6ace3c;
}

.notifications-box {
  width: 100%;
  padding: 12px;
  background: #fafbfc;
  border-bottom: 1px solid #eaeaea;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
}

.notifications-box:hover {
  background-color: #f0f7f9;
}

.notifications-box .notifications-text {
  width: 200px;
  color: #9d9fa2;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  white-space: nowrap;
}

.notifications-box.white {
  background-color: #fff;
  justify-content: center;
  padding: 6px;
}

.notifications-box.white .notifications-text {
  width: 100%;
  text-align: center;
}

.notifications-box .label-btn {
  width: auto;
  display: inline;
  padding: 0.6em 0.9em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}

.notifications-box .label-btn.green {
  background-color: #62cb31;
}

.notifications-box .label-btn.yellow {
  background-color: #f0ad4e;
}

.notifications-box .label-btn.red {
  background-color: #d9534f;
}
