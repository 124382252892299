.full-page-center {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.donors-details-body {
  width: 100%;

  .donor-details-header {
    border-top: 4px solid #007c16;
    padding: 20px;

    .crm-users-info {
      width: 100%;

      .user-name-email {
        @media (max-width: 768px) {
          margin-top: 20px;
        }

        .media-name {
          font-size: 36px;
          margin-bottom: 10px;

          @media (max-width: 540px) {
            font-size: 24px;
            margin-bottom: 0;
          }
        }

        h4 {
          font-size: 18px;
          margin-bottom: 12px;
          display: flex;
          align-items: center;

          @media (max-width: 540px) {
            display: none;
          }

          svg {
            margin-right: 5px;
          }
        }
      }

      @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
    }
  }

  /* Styling for tabs container */
  .tabs-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .tab {
      flex: 1;
      text-align: center;
      padding: 15px 0;
      cursor: pointer;
      font-weight: bold;
      transition: all 0.3s ease;
      text-transform: uppercase;
      letter-spacing: 0.09em;
      background-color: #fff;

      &.active {
        border-top: 4px solid #007c16;
        color: #007c16;
      }

      &:hover {
        color: #007c16;
      }
    }
  }

  .tab-content {
    .parent-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .box {
        flex: 0 0 calc(25% - 40px);
        height: 300px;
        background-color: #fff;
        margin: 20px;
        border: 1px solid #eaeaea;
        border-radius: 2px;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        @media (max-width: 1280px) {
          flex: 0 0 calc(50% - 40px);
        }

        @media (max-width: 768px) {
          flex: 0 0 100%;
          margin: 0;
          margin-bottom: 20px;
        }
        .box-header {
          padding: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #eaeaea;
          position: relative;
          span {
            font-weight: 600;
            color: #6a6c6f;
            letter-spacing: 0.09em;
          }
          .popup {
            top: 35px;
            right: 0;
          }
        }
        .lifetime-content-wrapper {
          flex: 1;
          background-color: #fff;
          padding: 10px;
          position: relative;
          .content-wrappr-loader {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .data-item {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            padding: 10px 0;

            .data-label {
              flex: 1;
              font-weight: bold;
            }
          }
        }
      }
    }
    // recent transactions
    .table-container {
      margin-top: 20px;

      .box {
        width: calc(100% - 40px);
        // height: fit-content;
        height: 350px;
        // max-height: 350px;
        background-color: #fff;
        margin: 0 auto;
        border-radius: 1px solid #eaeaea;
        border-radius: 2px;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        @media (max-width: 768px) {
          width: calc(100% - 0px);
        }
        .box-header {
          padding: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #eaeaea;
          position: relative;
          span {
            font-weight: 600;
            color: #6a6c6f;
            letter-spacing: 0.09em;
          }
          .popup {
            top: 35px;
            right: 0;
          }
        }
        .lifetime-content-wrapper {
          flex: 1;
          // min-height: 180px;
          background-color: #fff;
          padding: 10px;
          position: relative;
          overflow-y: auto;

          .content-wrappr-loader {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .lifetime-content-wrapper::-webkit-scrollbar {
          width: 6px;
          height: 6px;
        }

        .lifetime-content-wrapper::-webkit-scrollbar-thumb {
          background-color: #e1e1e9;
          border-radius: 3px;
        }

        .lifetime-content-wrapper::-webkit-scrollbar-thumb:hover {
          background-color: #b1b1b9;
        }

        .lifetime-content-wrapper::-webkit-scrollbar-track {
          background-color: #f1f1f1;
        }

        .lifetime-content-wrapper::-webkit-scrollbar-track:hover {
          background-color: rgba(0, 0, 0, 0.1);
        }
      }
    }
    // year over years
    .year-over-years-container {
      margin-top: 20px;

      .box {
        width: calc(100% - 40px);
        height: 400px;
        background-color: #fff;
        margin: 0 auto;
        border-radius: 1px solid #eaeaea;
        border-radius: 2px;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        @media (max-width: 768px) {
          width: calc(100% - 0px);
        }
        .box-header {
          padding: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #eaeaea;
          position: relative;
          span {
            font-weight: 600;
            color: #6a6c6f;
            letter-spacing: 0.09em;
          }
          .popup {
            top: 35px;
            right: 0;
          }
        }
        .lifetime-content-wrapper {
          flex: 1;
          background-color: #fff;
          padding: 10px;
          position: relative;
          overflow-y: auto;

          .content-wrappr-loader {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .donor-line-chart-container {
            width: 100%;
            height: 100%;
          }
        }
        .lifetime-content-wrapper::-webkit-scrollbar {
          width: 6px;
          height: 6px;
        }

        .lifetime-content-wrapper::-webkit-scrollbar-thumb {
          background-color: #e1e1e9;
          border-radius: 3px;
        }

        .lifetime-content-wrapper::-webkit-scrollbar-thumb:hover {
          background-color: #b1b1b9;
        }

        .lifetime-content-wrapper::-webkit-scrollbar-track {
          background-color: #f1f1f1;
        }

        .lifetime-content-wrapper::-webkit-scrollbar-track:hover {
          background-color: rgba(0, 0, 0, 0.1);
        }
      }
    }
    // campaigns summary
    .campaigns-summary-container {
      margin-top: 20px;

      .box {
        width: calc(100% - 40px);
        height: 500px;
        background-color: #fff;
        margin: 0 auto;
        border-radius: 1px solid #eaeaea;
        border-radius: 2px;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        @media (max-width: 768px) {
          width: calc(100% - 0px);
        }
        .box-header {
          padding: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #eaeaea;
          position: relative;
          span {
            font-weight: 600;
            color: #6a6c6f;
            letter-spacing: 0.09em;
          }
          .popup {
            top: 35px;
            right: 0;
          }
        }
        .lifetime-content-wrapper {
          flex: 1;
          background-color: #fff;
          padding: 10px;
          position: relative;
          overflow-y: auto;
          .gauge-container {
            display: flex;
            flex-wrap: wrap;

            .gauge {
              width: calc(25% - 10px);
              margin: 0 5px 30px;
              border-radius: 2px;
              background-color: #fff;
              box-sizing: border-box;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              border-radius: 2px;

              p {
                margin-top: -20px;
                font-weight: 600;
                color: #6a6c6f;
              }

              h2 {
                font-size: 18px;
                margin-bottom: 10px;
              }

              @media (max-width: 768px) {
                width: calc(50% - 10px);
              }

              @media (max-width: 480px) {
                width: 100%;
                margin: 0 0 10px;
              }
            }

            .rgc-circle {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }

          .content-wrappr-loader {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .lifetime-content-wrapper::-webkit-scrollbar {
          width: 6px;
          height: 6px;
        }

        .lifetime-content-wrapper::-webkit-scrollbar-thumb {
          background-color: #e1e1e9;
          border-radius: 3px;
        }

        .lifetime-content-wrapper::-webkit-scrollbar-thumb:hover {
          background-color: #b1b1b9;
        }

        .lifetime-content-wrapper::-webkit-scrollbar-track {
          background-color: #f1f1f1;
        }

        .lifetime-content-wrapper::-webkit-scrollbar-track:hover {
          background-color: rgba(0, 0, 0, 0.1);
        }
      }
    }
  }

  .highlights-loadings {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 768px) {
  /* Apply flex-direction column on smaller screens */
  .tabs-container {
    flex-direction: column;
    align-items: center;
  }

  .tab {
    flex: none; /* Reset flex value */
    width: 100%; /* Take full width in column layout */
  }

  .donor-details-header {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .crm-users-info.flex-start {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .donor-circle {
        margin-bottom: 10px;
      }
      .user-name-email {
        .media-name {
          text-align: center;
          font-size: 32px;
        }
        h4 {
          margin: 12px 5px;
          font-size: 14px;
        }
        .h4-last {
          margin-top: 0;
        }
      }
    }
  }
}

.donor-link:hover {
  color: #007c16 !important;
}

/* custom radio btns */
.custom-btn-group {
  box-shadow: none !important;
  margin-bottom: 25px;
  margin-top: -5px;
}

.btn-transparent {
  background-color: #fff;
  color: #007c16;
}

.btn-check:checked + .btn-theme-green,
.btn-check:active + .btn-theme-green,
.btn-theme-green:active,
.btn-theme-green.active,
.show > .btn-theme-green.dropdown-toggle {
  color: #fff;
  background-color: #007c16;
}

.donor-circle {
  min-width: 250px;
  min-height: 250px;
  background-color: #007c16;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-right: 25px;

  @media (max-width: 540px) {
    margin-right: 0;
    margin-bottom: 5px;
  }
}

.donor-circle img {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  object-fit: cover;
  overflow-clip-margin: unset;
}

.donor-circle .upload-or-edit-icon-cirle {
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #f8f8f8;
  border: 1px solid #007c16;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-file {
  display: none;
}

.donor-circle .upload-or-edit-icon-cirle label {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.donor-circle h3 {
  font-size: 36px;
  font-weight: 600;
}

.delete-photo-btn {
  background-color: #dc3546 !important;
  min-width: 150px;
  margin-top: 25px;
  box-shadow: none !important;

  @media (max-width: 540px) {
    margin-top: 10px;
  }
}

.save-btn {
  background-color: #007c16 !important;
  width: 100%;
  margin: 15px 0;
  box-shadow: none !important;
}

.dp-container {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  background-color: #f7f8fa;
  border: 1px solid #007c16;
  overflow: hidden;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dp-container img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  overflow-clip-margin: unset;
}
