.manage-organization {
  width: 100%;

  /* Styling for tabs container */
  .tabs-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .tab {
      flex: 1;
      text-align: center;
      padding: 15px 0;
      cursor: pointer;
      font-weight: bold;
      transition: all 0.3s ease;
      text-transform: uppercase;
      letter-spacing: 0.09em;
      background-color: #fff;

      &.active {
        border-top: 4px solid #007c16;
        color: #007c16;
      }

      &:hover {
        color: #007c16;
      }
    }
  }

  .update-media-form {
    background-color: #fff;
    margin-top: 15px;
    border-radius: 2px;
    border: 1px solid #eaeaea;
    padding: 15px;
    .logo-row {
      @media (max-width: 767px) {
        flex-direction: column-reverse;
      }
      .logo-col {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        img {
          width: 60px;
          height: auto;
        }
      }
    }
    .qr-input-container {
      display: flex;
      align-items: center;
      .qr-button {
        width: 160px;
        background-color: #6a6c6f;
        color: #fff;
        padding: 6px 10px;
        margin-left: 3px;
        font-size: 16px;
        border: hidden;
        outline: none;
        border-radius: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .signature-col {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;
    div.sign-img-container {
      width: 150px;
      height: 60px;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        bottom: -12px;
      }
    }
    .update-sign-popup-btn {
      padding: 6px 10px;
      background-color: #007c16;
      color: #fff;
      border: none;
      outline: none;
      border-radius: 2px;
    }
  }
}

a.generate-signature-hyperlink {
  color: #007c16;
  font-size: 16px;
  margin-top: 3px;
  margin-bottom: 15px;
  display: block;
  width: 100%;
}
